@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsExtraBold";
  src: url("./fonts/Poppins-ExtraBold.ttf");
}
@font-face {
  font-family: "PoppinsExtraLight";
  src: url("./fonts/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: "PoppinsExtraLightItalic";
  src: url("./fonts/Poppins-ExtraLightItalic.ttf");
}
@font-face {
  font-family: "PoppinsItalic";
  src: url("./fonts/Poppins-Italic.ttf");
}
@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "PoppinsMedium";
  src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "PoppinsThin";
  src: url("./fonts/Poppins-Thin.ttf");
}

.custom-checkbox {
  @apply appearance-none w-5 h-5 border-2 border-authbordercolor rounded-md bg-white checked:bg-primary checked:border-primary relative cursor-pointer;
}

.custom-checkbox:checked::before {
  content: "";
  @apply absolute w-2 h-3 border-b-2 border-r-2 border-white transform rotate-45 top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/3;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-progress-bar {
  background-color: #ffffff;
  border-radius: 4px;
  /* width: ; */
}

.custom-levelone-progress-bar .p-progressbar-value {
  background-color: #e80404;
  border-radius: 4px;
}
.custom-leveltwo-progress-bar .p-progressbar-value {
  background-color: #ffc700;
  border-radius: 4px;
}
.custom-levelthree-progress-bar .p-progressbar-value {
  background-color: #00936d;
  border-radius: 4px;
}

.slider-container .slick-slide {
  padding: 0 10px;
}

.slider-container .slick-list {
  margin: 0 -10px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* DatePickerStyles.css */
.ant-picker-active-bar {
  background: #6b006b !important; /* Change this to your desired color */
  color: white !important; /* Change text color if needed */
}

.logout {
  @apply bg-error text-error;
  background-color: rgba(255, 0, 0, 0.25);
  color: #e80404 !important;
}
.pulse {
  animation: animate 3s infinite;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgba(170, 0, 255, 0.5);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(170, 0, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(170, 0, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(170, 0, 255, 0);
  }
}
.pulseupload {
  animation: animated 3s infinite;
}

@keyframes animated {
  0% {
    box-shadow: 0 0 0 0 rgba(195, 0, 255, 0.5);
  }
  40% {
    box-shadow: 0 0 0 10px rgba(195, 0, 255, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(195, 0, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(195, 0, 255, 0);
  }
}
.tooltip-arrow {
  position: absolute;
  bottom: -10px;
  right: 125px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent; /* Adjust the color */
  rotate: 180deg;
}
